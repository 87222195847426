import React from 'react'
import './BlockLogo.scss'

import l1 from '../../assets/img/l1.jpeg'
import l2 from '../../assets/img/l2.jpeg'
import l3 from '../../assets/img/l3.jpeg'
import l4 from '../../assets/img/l4.jpeg'
import l5 from '../../assets/img/l5.jpeg'
import l6 from '../../assets/img/l6.jpeg'
import l7 from '../../assets/img/l7.jpeg'
import l8 from '../../assets/img/l8.jpeg'

const BlockLogo = () => {
  return (
    <div className="blockLogo" id="brends">
      <img src={l2} alt=""/>
      <img src={l4} alt=""/>
      <img src={l3} alt=""/>
      <img src={l6} alt=""/>
      <img src={l5} alt=""/>
      <img src={l1} alt=""/>
      <img src={l8} alt=""/>
      <img src={l7} alt=""/>
    </div>
  )
}

export default BlockLogo