import React from 'react'
import './App.scss'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { CSSTransition } from 'react-transition-group'

import Header from './Header/Header'
import Main from './Main/Main'
import Footer from './Footer/Footer'
import BlockLogoAll from './BlockLogoAll/BlockLogoAll'
import BlockNewsAll from './BlockNewsAll/BlockNewsAll'
import Form from './Form/Form'
import Floor from './Floor/Floor'
import ButtonUp from './ButtonUp/ButtonUp'
import NewProject from './NewProject/NewProject'

const routes = [
  { path: '/', Component: Main },
  { path: '/allBrends', Component: BlockLogoAll },
  { path: '/allNews', Component: BlockNewsAll },
  { path: '/floor', Component: Floor },
  { path: '/arenda', Component: Form },
  { path: '/newProject', Component: NewProject }
]

const App = () => {
  return (
    <Router>
      <Header />
        { routes.map( ({ path, Component }) => (
          <Route key={path} exact path={path}>
            { ({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="transPage"
                unmountOnExit
              >
                <div className="transPage">
                  <Component />
                  <ButtonUp />
                </div>
              </CSSTransition>
            )}
          </Route>
        )) }
      <Footer />
    </Router>
  )
}

export default App
