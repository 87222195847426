import React, { useState } from 'react'
import './Form.scss'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import ScrollToTopOnMount from '../ScrollToTopOnMount'

const Form = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })

  const [compani, setCompani] = useState('')
  const [profile, setProfile] = useState('')
  const [square, setSquare] = useState('')
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  function hendelSubmit(event) {
    event.preventDefault()
    axios.post('/mail', {
      compani: compani,
      profile: profile,
      square: square,
      name: name,
      mail: mail,
      phone: phone
    })
    .then( (res) => {
      if (res.data.msg === 'success') { setMessage(t('mSuccess')) } 
      if (res.data.msg === 'fail') { setMessage(t('mFail')) }
    })
    .catch( (err) => { setMessage(err.messag) } )
  }

  return (
    <div className="form-conteiner">
      <ScrollToTopOnMount />
      <div className="form-text"> {t('formH')} </div>
      <form onSubmit={hendelSubmit}>
        <label>
          {t('form1')}
          <input
            value={compani}
            onChange={event => setCompani(event.target.value)}
            type="text"
            required
          />
        </label>
        <label>
          {t('form2')}
          <input
            value={profile}
            onChange={event => setProfile(event.target.value)}
            type="text"
            required
          />
        </label>
        <label>
          {t('form3')}
          <input
            value={square}
            onChange={event => setSquare(event.target.value)}
            type="text"
            required
          />
        </label>
        <label>
          {t('form4')}
          <input
            value={name}
            onChange={event => setName(event.target.value)}
            type="text"
            required
          />
        </label>
        <label>
          {t('form5')}
          <input
            value={mail}
            onChange={event => setMail(event.target.value)}
            type="email"
            required
          />
        </label>
        <label>
          {t('form6')}
          <input
            value={phone}
            onChange={event => setPhone(event.target.value)}
            type="tel"
            required
          />
        </label>
       <div> {message} </div>
       <button type="submit"> {t('submit')} </button>
      </form>
    </div>
  )
}

export default Form