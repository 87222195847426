import React from 'react'
import './Floor.scss'
import { useTranslation } from 'react-i18next'

import SvgFloor1 from '../../assets/icon/floor1.svg'
import SvgFloor2 from '../../assets/icon/floor2.svg'

const Floor = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <div className="floor">
      <div> {t('floor1')} </div>
      <img src={SvgFloor1} alt=""/>
      <div> {t('floor2')} </div>
      <img src={SvgFloor2} alt=""/>
    </div>
  )
}

export default Floor