import React from 'react'
import './BlockLogoAll.scss'
import ScrollToTopOnMount from '../ScrollToTopOnMount'


const arrBrends = ['ProStor', 'YVES ROCHER', 'brabrabra', 'БілизнаStreet', 'NOVUS', 'К-Маркет', 'Секунда', 'ТОВМАХ',     'BESTEP', 'Мистер Мастер', 'НОВА ПОШТА', 'Vodafonе', 'АЛЛО', 'МТА', 'Seta Decor', 'Дескомаркет', 'Київстар', 'Оптика', 'Фабрика сумок', 'Sezone', 'Lifecell', 'Фитомаркет', 'Chantale', 'PrintStudio', 'BeautyK', 'Coral Travel', 'ПриватБанк', 'Фортуна Табак', 'Xmondial', 'Annasun', 'Bar GymFit', 'Karmi', 'Sweetland', 'Самоцветы', 'Сувенир Престиж', 'Османтус', '925','BARGELLO', 'YUPPIE', 'СРІБНА КРАІНА', 'Срібна Кульбаба', 'Пузата Хата', 'Руккола',    'Наливки зі Львова', 'SEASON', 'muztorg', 'Smeraldo', 'Насолода', 'For Kids outlet', 'Дім білизни', 'Square Store',    'Casual Man', 'New Balance', 'Grand Outlet', 'Бомонд', 'Garment Hill', 'RED', 'RUVI', 'Lawine', 'Anabel Arto', 'Adele',   'Natali Bolgar', '7case', 'Экспресс маникюр', 'Parfums.ua', 'Сказка', 'HUB волосся', 'Американская химчистка', 'Аптека Доброго Дня', 'Столична Ювелірна Фабрика', 'Восточные сладости', 'Львівська майстерня шоколаду']

const BlockLogoAll = () => {
  return (
    <div className="logoAll">
    <ScrollToTopOnMount />
      { arrBrends.map( (item, i) => 
          <div key={i}> {item} </div>
      ) }
    </div>
  )
}

export default BlockLogoAll