import React from 'react'
import './BlockNewsAll.scss'

import BlockNews from '../BlockNews/BlockNews'
import ScrollToTopOnMount from '../ScrollToTopOnMount'

const BlockNewsAll = () => {
  return (
    <div className="newsAll">
      <ScrollToTopOnMount />
      <BlockNews postUrl='/newsAll' />
    </div>
  )
}

export default BlockNewsAll