import React from 'react'
import './BlockAbout.scss'
import { useTranslation } from 'react-i18next'


const BlockAbout = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <div className="about" id="about">
      <div className="about-text">
        <h6> {t('aboutH')} </h6>
        <div> {t('aboutT')} </div>
      </div>
      <div className="about-foto"></div>
    </div>
  )
}

export default BlockAbout