import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ua from './ua.json'
import ru from './ru.json'
import en from './en.json'


i18n
  .use(initReactI18next)
  .init({
    resources: {
      ua: ua,
      ru: ru,
      en: en
    },
    lng: "ua",
    fallbackLng: "ua",
    debug: false,
    interpolation: {
      escapeValue: false
    }
  })
 
export default i18n