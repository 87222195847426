import React from 'react'
import './Main.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Foto from '../Foto/Foto'
import HText from '../HText/HText'
import BlockLogo from '../BlockLogo/BlockLogo'
import BlockNews from '../BlockNews/BlockNews'
import BlockAbout from '../BlockAbout/BlockAbout'
import BlockLocation from '../BlockLocation/BlockLocation'



const Main = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <main>
      <Foto />
      <HText th1={t('ht1')} th2={t('ht2')} />
      <BlockLogo />
      <Link to="/allBrends" className="buttonAll"> {t('bAll1')} </Link>
      <HText th1={t('ht3')} th2={t('ht4')} />
      <BlockNews postUrl='/news' />
      <Link to="/allNews" className="buttonAll"> {t('bAll2')} </Link>
      <HText th1={t('ht5')} th2={t('ht6')} />
      <BlockAbout />
      <HText th1={t('ht7')} th2={t('ht8')} />
      <BlockLocation />
    </main>
  )
}

export default Main