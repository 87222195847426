import React from 'react'
import './HText.scss'

const HText = ({ th1, th2 }) => {
  return (
    <>
      <h3> {th1} </h3>
      <h4> {th2} </h4>
    </>
  )
}

export default HText