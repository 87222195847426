import React from 'react'
import './Footer.scss'
import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'

import { ReactComponent as SvgLogo } from '../../assets/icon/logo.svg'
import { ReactComponent as SvgCopyright } from '../../assets/icon/copyright.svg'

const Footer = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <footer>
      <div className="footer-flex">
        <SvgLogo />
        <div>
          <HashLink to='/#about' rel="nofollow"> {t('footA1')} </HashLink>
          <HashLink to='/#news' rel="nofollow"> {t('footA2')} </HashLink>
          <HashLink to='/#brends' rel="nofollow"> {t('footA3')} </HashLink>
          <HashLink to='/arenda' rel="nofollow"> {t('footA4')} </HashLink>
          <HashLink to='/newProject' rel="nofollow"> {t('footA5')} </HashLink>
        </div>
        <div>
          <div className="label"> {t('footL1')} </div>
          <div className="text"> {t('f-s1')} </div>
          <div className="label"> {t('footL2')} </div>
          <div className="text"> 10.00 - 22.00 <br/> 07:00 - 23:00 NOVUS </div>
        </div>
        <div>
          <div className="label"> {t('footL3')} </div>
          <div className="text">(067) 321-14-11</div>
          <div className="label"> {t('footL4')} </div>
          <div className="text"> DomaCenterArenda@gmail.com <br/> DomaCenterPartnership@gmail.com </div>
        </div>
      </div>
      <a className="copyright" href="https://www.salt.com.ua" title="Web-Studio Salt"  target="_blank" rel="noopener      noreferrer">
        {/* by Alexander Burkatskiy  */}
        <SvgCopyright /> 
        _Salt 
      </a>
    </footer>
  )
}

export default Footer