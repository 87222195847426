import React from 'react'
import './Header.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SvgLogo } from '../../assets/icon/logo.svg'
import { ReactComponent as SvgLogo2 } from '../../assets/icon/logo2.svg'
import Nav from '../Nav/Nav'

const Header = () => {
  const { i18n } = useTranslation()
  return (
    <header>
      <div className="top">
        <Link to="#" className="lang" onClick={() => i18n.changeLanguage('ua')}> UA </Link>
        <Link to="#" className="lang" onClick={() => i18n.changeLanguage('ru')}> RU </Link>
        <Link to="#" className="lang" onClick={() => i18n.changeLanguage('en')}> EN </Link>
        <Nav />
      </div>
      <div className="square">
        <Link to="/">
          <SvgLogo />
        </Link>
        <Link to="/newProject">
          <SvgLogo2 />
        </Link>
      </div>
    </header>
  )
}

export default Header