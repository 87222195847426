import React from 'react'
import './BlockLocationTwo.scss'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SvgPlaceholder } from '../../assets/icon/placeholder.svg'
import { ReactComponent as SvgMetro } from '../../assets/icon/metro.svg'

const BlockLocationTwo = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <>
      <div className="locationTwo" id="locationTwo">
        <div className="textTwo-1"> DOMA center 2 </div>
        <div className="textTwo-2"> {t('loc1')} </div>
        <div className="textTwo-svg">
          <SvgPlaceholder />
          <span> {t('f-s2')} </span>
        </div>
        <div className="textTwo-svg">
          <SvgMetro />
          {t('f-s22')}
        </div>
      </div>
      <iframe title="Карта" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2543.2224969230724!2d30.628748815812532!3d50.39969089890192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2s9G2G9JXJ%2BV9!5e0!3m2!1sru!2sua!4v1623525117142!5m2!1sru!2sua" loading="lazy"></iframe>
    </>
  )
}

export default BlockLocationTwo