import React from 'react'
import './BlockAboutTwo.scss'
import { useTranslation } from 'react-i18next'


const BlockAboutTwo = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <div className="aboutTwo" id="aboutTwo">
      <div className="aboutTwo-text">
        <h6> {t('aboutH')} </h6>
        <div> {t('aboutTwoT')} </div>
      </div>
      <div className="aboutTwo-foto"></div>
    </div>
  )
}

export default BlockAboutTwo