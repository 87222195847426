import React from 'react'
import './NewProject.scss'
import { useTranslation } from 'react-i18next'
import ScrollToTopOnMount from '../ScrollToTopOnMount'

import HText from '../HText/HText'
import BlockAboutTwo from '../BlockAboutTwo/BlockAboutTwo'
import BlockLocationTwo from '../BlockLocationTwo/BlockLocationTwo'


const NewProject = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })

  return (
    <div className="project-conteiner">
      <ScrollToTopOnMount />
      <div className="project-foto">
        <div> COMING SOON </div>
      </div>
      <HText th1={t('ht9')} th2={t('ht10')} />
      <BlockAboutTwo />
      <BlockLocationTwo />
    </div>
  )
}

export default NewProject