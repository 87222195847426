import React from 'react'
import './Foto.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SvgPlaceholder } from '../../assets/icon/placeholder.svg'
import { ReactComponent as SvgClock } from '../../assets/icon/clock.svg'
import { ReactComponent as SvgTelephone } from '../../assets/icon/telephone.svg'
import { ReactComponent as SvgMail } from '../../assets/icon/mail.svg'
import { ReactComponent as SvgSquare } from '../../assets/icon/square.svg'
import { ReactComponent as SvgStairs } from '../../assets/icon/stairs.svg'
import { ReactComponent as SvgParking } from '../../assets/icon/parking.svg'

const Foto = () => {
const { t } = useTranslation('ns1', { useSuspense: false })

  return (
    <div className="foto">
      <h2> {t('foto-H2')} </h2>
      <div className="foto-right">
        <a href="#location" rel="nofollow">
          {t('f-s1')} 
          <section> {t('f-s11')} </section>
        </a>
        <SvgPlaceholder />
      </div>
      <div className="foto-right">
        <section> 10.00 - 22.00 </section>
        <SvgClock />
      </div>
      <div className="foto-right">
        <a href='tel:+380673211411' rel="nofollow"> (067) 321-14-11 </a>
        <SvgTelephone />
      </div>
      <div className="foto-right">
        <a href='mailto:DomaCenterArenda@gmail.com' rel="nofollow"> DomaCenterArenda<br/>@gmail.com </a>
        <SvgMail />
      </div>
      <div className="order">
        <Link to="/arenda" rel="nofollow"> {t('f-order')} </Link>
      </div>
      <div className="foto-bottom">
        <div>
          <SvgSquare />
          <div> {t('f-b1')} </div>
          <div> {t('f-b11')} </div>
        </div>
        <div>
          <Link to="/floor">
            <SvgStairs />
            <div> {t('f-b2')} </div>
            <div> {t('f-b22')} </div>
          </Link>
        </div>
        <div>
          <SvgParking />
          <div> {t('f-b3')} </div>
          <div> {t('f-b33')} </div>
        </div>
      </div>
    </div>
  )
}

export default Foto