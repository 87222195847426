import React, { useState, useEffect } from 'react'
import './ButtonUp.scss'
import { Link } from 'react-router-dom'

import { ReactComponent as SvgArrowUp } from '../../assets/icon/arrow-up.svg'


const ButtonUp = () => {
  const [scroll, setScroll] = useState(window.scrollY)

  useEffect(() => {
    const hendleScroll = () => { setScroll(window.scrollY) }
    window.addEventListener('scroll', hendleScroll)
    return () => {
      window.removeEventListener('scroll', hendleScroll)
    }
  })

  return(
    <Link to="#" rel="nofollow" title="up" 
      className={ scroll > 300 ? 'up' : 'up-hide' } 
      onClick={ () => {window.scrollTo(0, 0)} }
    >
      <SvgArrowUp />
    </Link>
  )
}

export default ButtonUp