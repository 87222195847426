import React, { useState } from 'react'
import './Nav.scss'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import { ReactComponent as SvgMenu } from '../../assets/icon/menu.svg'

const Nav = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  const [toggle, setToggel] = useState(false)
  return (
    <nav>
      <SvgMenu onClick={ () => setToggel(!toggle) } />
      <CSSTransition 
        in={toggle} 
        timeout={400} 
        classNames="transMenu" 
        unmountOnExit
      >
        <ul onClick={ () => setToggel(!toggle) }>
          <li><Link to="/"> {t('menu6')} </Link></li>
          <li><HashLink to="/#about"> {t('menu1')} </HashLink></li>
          <li><HashLink to="/#news"> {t('menu2')} </HashLink></li>
          <li><Link to="/newProject"> {t('menu7')} </Link></li>
          <li><Link to="/allBrends"> {t('menu3')} </Link></li>
          <li><Link to="/floor"> {t('menu4')} </Link></li>
          <li><Link to="/arenda"> {t('menu5')} </Link></li>
        </ul>
      </CSSTransition> 
    </nav>
  )
}

export default Nav