import React, { useState, useEffect } from 'react'
import './BlockNews.scss'

import axios from 'axios'


const BlockNews = ({ postUrl }) => {
  const [activeNews, setActiveNews] = useState(null)
  const [data, setData] = useState([
    { img: '', label: 'Loading', text: '' },
    { img: '', label: 'Loading', text: '' },
    { img: '', label: 'Loading', text: '' },
    { img: '', label: 'Loading', text: '' }
  ])

  function activeElement(index) { 
    activeNews === index ? setActiveNews(null) : setActiveNews(index)
  }

  useEffect( () => {
    const fetchData = async () => {
      const result = await axios.post(postUrl)
      setData(result.data)
    }
    fetchData()
  }, [postUrl])

  return (
    <div className="news" id="news">
      { data.map( (item, index) =>
        <div key={index} className={index === activeNews ? 'openNews' : null} onClick={ () => activeElement(index) }>
					<img src={item.img} alt=""/> 
          <h5> {item.label} </h5>
          <p> {item.text} </p>
        </div>
			) }
    </div>
  )
}

export default BlockNews