import React from 'react'
import './BlockLocation.scss'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SvgPlaceholder } from '../../assets/icon/placeholder.svg'
import { ReactComponent as SvgClock } from '../../assets/icon/clock.svg'
import { ReactComponent as SvgMetro } from '../../assets/icon/metro.svg'

const BlockLocation = () => {
  const { t } = useTranslation('ns1', { useSuspense: false })
  return (
    <>
      <div className="location" id="location">
        <div className="text-1"> DOMA center </div>
        <div className="text-2"> {t('loc1')} </div>
        <div className="text-svg">
          <SvgPlaceholder />
          {t('f-s1')}
        </div>
        <div className="text-svg">
          <SvgMetro />
          {t('f-s11')}
        </div>
        <div className="text-svg">
          <SvgClock />
          10.00 - 22.00 <br/> 07:00 - 23:00 NOVUS
        </div>
      </div>
      <iframe title="Карта" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.264512828201!2d30.60896251605572!3d50.45479887947591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cffbc3fa0e1d%3A0x42ddd9d69dd6c5b0!2z0KLQpiBET01BIGNlbnRlciwg0LLRg9C70LjRhtGPINCR0YPQtNGW0LLQtdC70YzQvdC40LrRltCyLCA0MCwg0JrQuNGX0LIsIDAyMDAw!5e0!3m2!1sru!2sua!4v1555519582796!5m2!1sru!2sua"></iframe>
    </>
  )
}

export default BlockLocation